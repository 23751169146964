import styled from 'styled-components';

const VideoSectionWrapper = styled.section`
  padding: 120px 0;
  overflow: hidden;

  @media (max-width: 990px) {
    padding-top: 60px;
  }
  @media (max-width: 767px) {
    padding-top: 60px;
  }

  .figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
      border-radius: 4px;
    }

    .fig__caption {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .btn_shadow {
        transition: 0.900s ease;
        border-radius: 100px;
        &:hover{
          box-shadow: 0 0 60px rgba(0, 0, 0, 0.20);
      }
      }
    }
  }
`;

export default VideoSectionWrapper;
