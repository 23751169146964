import styled from 'styled-components';

const SectionWrapper = styled.section`
  margin-top: 60px;
  background-color: unset;
  
  .box-wrapper {
    @media (max-width: 425px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .service_item {
    position: relative;
    text-align: center;
    .service_icon {
      width: 60px;
      height: 60px;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      margin: 0 auto 16px auto;
      color: #ff4361;
      @media (max-width: 990px) {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        font-size: 36px;
        margin-bottom: 10px;
      }
      @media (max-width: 425px) {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        font-size: 18px;
      }
    }
  }

  .service_item_col {
    &:nth-child(1) {
      .service_item {
        .service_icon {
          font-size: 46px;
          @media (max-width: 990px) {
            font-size: 40px;
          }
        }
      }
    }

    &:nth-child(2) {
      .service_item {
        .service_icon {
          font-size: 46px;
          @media (max-width: 990px) {
            font-size: 40px;
          }
        }
        h3 {
          color: #5E514D;
        }
      }
    }

    &:nth-child(3) {
      .service_item {
        .service_icon {
          font-size: 46px;
          @media (max-width: 990px) {
            font-size: 40px;
          }
        }
        h3 {
          color: #5E514D;
        }
      }
    }

    &:nth-child(4) {
      .service_item {
        .service_icon {
          font-size: 46px;
          @media (max-width: 990px) {
            font-size: 40px;
          }
        }
        h3 {
          color: #5E514D;
        }
      }
    }

    &:nth-child(5) {
      .service_item {
        .service_icon {
          font-size: 46px;
          @media (max-width: 990px) {
            font-size: 40px;
          }
        }
        h3 {
          color: #5E514D;
        }
      }
    }
  }
`;

export default SectionWrapper;
